import NeonTitle from "./neon-title/NeonTitle";
import MediaPlayer from "./media-player/MediaPlayer";
import CinematicBars from "./cinematic-bars/CinematicBars";
import CountdownTimer from "./countdown-timer/CountdownTimer";
import SocialMediaTicker from "./social-media-ticker/SocialMediaTicker";
import PrutsCounter from "./counter/Counter";
import HeaderLogo from "./header-logo/HeaderLogo";
import QrCode from "./qr-code/QrCode";
import { PreloadMediaProvider } from "./PreloadMediaProvider/PreloadMediaProvider";
import Confetti from "./confetti/Confetti";
import Snowfall from "./snowfall/Snowfall";

const overlayCanvasStyle : React.CSSProperties = {
    backgroundColor: 'black',
    position: 'fixed',
    height: '1080px',
    width: '1920px',
    zIndex: '-100',
}

const OverlayCanvas = () => {

    return (
        <div className="VideoCanvas" style={overlayCanvasStyle}>
            {/* <Confetti/> */}
            <CinematicBars />
            <HeaderLogo />
            <NeonTitle />
            <PreloadMediaProvider>
                <MediaPlayer />
            </PreloadMediaProvider>
            <CountdownTimer />
            <PrutsCounter/>
            {/* <Snowfall /> */}
            <SocialMediaTicker />
            <QrCode/>
        </div>
    );
}

export default OverlayCanvas;
