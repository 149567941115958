import { useAppSelector } from '../../hooks';
import { CounterProps } from './CounterSlice';
import './Counter.css';

export default function Counter() {
  const props: CounterProps = useAppSelector((state: any) => state.counter);

  return (
    <div className="counter">
      <span>Shotjees left: {props.value}</span>
      <small>(Superchat to increase)</small>
    </div>
  );
}
