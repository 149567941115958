import { KeyboardTrigger } from './Keyboard'
import { MidiTrigger } from './Midi'
import { TwitchTrigger } from './Twitch'
import { YouTubeTrigger } from './YouTube'

const Triggers = () => {

    return (
        <div>
            <KeyboardTrigger />
            <MidiTrigger />
            {/* <TwitchTrigger /> */}
            <YouTubeTrigger />
        </div>
    )
}

export default Triggers;