import { useAppDispatch } from '../hooks';
import { appear } from '../fragment/neon-title/NeonTitleSlice';
import axios from 'axios';
import { increment } from '../fragment/counter/CounterSlice';
import { play } from '../fragment/media-player/MediaPlayerSlice';

const queryParameters = new URLSearchParams(window.location.search)
const config = {
    apiKey: queryParameters.get("youtube_api_key") || "",
    channelId: queryParameters.get("youtube_channel_id") || "",
    pollingInterval: 10000, // 10 sec
    triggers: [
        { type: "textMessageEvent", message: "Jägermeister", function: appear({ text: "🥃", amount: 8, color: "#ff0000", duration: 4000, }) },
        { type: "textMessageEvent", message: "Jägermeister", function: play("./res/jingles/jägermeister_schrei.mp3") },
        { type: "textMessageEvent", message: "!pruts", function: increment() },
        { type: "textMessageEvent", message: "HALLO", function: play("./res/jingles/hallo.wav") },
        { type: "superChatEvent", function: appear({ text: "New Superchat", amount: 8, color: "#ff0000", duration: 4000, })},
        { type: "superChatEvent", function: play("./res/jingles/rct_cash.mp3")},
        { type: "superChatEvent", function: increment()},
        { type: "superStickerEvent", function: appear({ text: "New Supersticker", amount: 8, color: "#ff0000", duration: 4000, })},
        { type: "superStickerEvent", function: play("./res/jingles/rct_cash.mp3")}
    ]
}

let messageIdHistory: string[] = [];

export const YouTubeTrigger = () => {
    const dispatch = useAppDispatch()

    fetchMostRecentLivestream().then(livestreamId => {
        if (livestreamId) {
            setInterval(() => fetchChatMessages(livestreamId, dispatch), config.pollingInterval)
        }
    })

    return (<div className="youtube-trigger"></div>)
}

const fetchMostRecentLivestream = async () => {
    try {
        console.log("Searching for livestream Id")

        const findVideoResponse = await axios.get(
            'https://www.googleapis.com/youtube/v3/search', {
            params: {
                part: 'snippet',
                channelId: config.channelId,
                type: 'video',
                eventType: 'live',
                maxResults: 1,
                key: config.apiKey,
            },
        }
        );

        const videoId = findVideoResponse.data.items[0].id.videoId;

        const findLiveChatIdResponse = await axios.get(
            'https://www.googleapis.com/youtube/v3/videos',
            {
                params: {
                    part: 'liveStreamingDetails',
                    id: videoId,
                    key: config.apiKey,
                },
            }
        );

        const liveStreamingDetails = findLiveChatIdResponse.data.items[0]?.liveStreamingDetails;
        if (liveStreamingDetails) {
            console.log('Found livestream:', liveStreamingDetails);
            return liveStreamingDetails.activeLiveChatId;
        }
    } catch (error) {
        console.error('Error fetching most recent livestream:', error);
    }
};

const fetchChatMessages = async (livestreamId: string, dispatch: any) => {
    try {
        const response = await axios.get(
            'https://www.googleapis.com/youtube/v3/liveChat/messages', {
            params: {
                liveChatId: livestreamId,
                part: 'snippet',
                key: config.apiKey,
            },
        }
        );

        console.log(response)
        console.log(messageIdHistory)
        response.data.items
            .filter((message: any) => !messageIdHistory.includes(message.id))
            .forEach((message: any) => {
                mapTrigger(message.snippet, dispatch)
                // Return a new array with message id in front, then the history
                messageIdHistory = [...messageIdHistory.slice(0, 150), message.id]
                console.log(`Added ${message.snippet.displayMessage}`)
            })

    } catch (error) {
        console.error('Error fetching YouTube live chat messages:', error);
    }
};

const mapTrigger = (snippet: any, dispatch: any) => {
    config.triggers.forEach((trigger: any) => {
        if (trigger.type === snippet.type && snippet.displayMessage.includes(trigger.message)) {
            dispatch(trigger.function)
        }
    })
}